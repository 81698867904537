import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';

import { Lang } from 'src/models/langs';
import { ProfessionalRoleTree } from 'src/models/professionalRoleTree';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/professional_role';

declare global {
    interface FetcherGetApi {
        [URL]: {
            queryParams: {
                lang: Lang;
            };
            response: ProfessionalRoleTree;
        };
    }
}

type TreeCacheType = Record<string, ProfessionalRoleTree>;

const professionalRoleTreeAction = makeSetStoreField('professionalRoleTree');

export const fetchProfessionalRoleTree = (
    lang: Lang,
    abortController?: AbortController
): Promise<ProfessionalRoleTree> =>
    fetcher.get(URL, {
        params: { lang },
        signal: abortController?.signal,
    });

export default function useFetchProfessionalRoleTree(): void {
    const treeCache = useRef<TreeCacheType>({});
    const dispatch = useDispatch();
    const lang = useContext(TranslationLangContext) as Lang;

    useEffect(() => {
        const abortController = new AbortController();

        if (!(lang in treeCache.current)) {
            fetchProfessionalRoleTree(lang, abortController)
                .then((data) => {
                    treeCache.current[lang] = data;
                    dispatch(professionalRoleTreeAction(data));
                })
                .catch((error) => {
                    if (fetcher.isCancel(error)) {
                        return;
                    }
                    console.error(error);
                });
        } else {
            dispatch(professionalRoleTreeAction(treeCache.current[lang]));
        }

        return () => abortController.abort();
    }, [lang, dispatch]);
}
